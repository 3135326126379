<template>
	<div class="fotter">
		<div class="fotter_content">
			<div class="footer_one">
				<div class="footer_one_list">
					<img src="../../src/assets/152.png" alt="">
					<span>正品保障</span>
				</div>
				<div class="footer_one_list">
					<img src="../../src/assets/153.png" alt="">
					<span>货期保证</span>
				</div>
				<div class="footer_one_list">
					<img src="../../src/assets/154.png" alt="">
					<span>专属客服</span>
				</div>
				<div class="footer_one_list">
					<img src="../../src/assets/155.png" alt="">
					<span>诚信服务</span>
				</div>
			</div>
			<div class="footer_two">
				<div class="footer_two_one" @click="gonext">
					<h3>购物指南</h3>
					<p>帮助中心</p>
				</div>
				<div class="footer_two_one">
					<h3>付款方式</h3>
					<p @click="openxieyi(30)">付款开票信息</p>
					<p @click="openxieyi(31)">如何支付</p>
				</div>
				<div class="footer_two_one">
					<h3>服务政策</h3>
					<p @click="openxieyi(35)">配送服务</p>
					<p @click="openxieyi(34)">售后服务</p>
					<p @click="openxieyi(2)">隐私政策</p>
				</div>
				<div class="footer_two_one">
					<h3>关于我们</h3>
					<p @click="openxieyi(20)">公司介绍</p>
					<p @click="openxieyi(32)">联系我们</p>
					<p @click="openxieyi(33)">加入我们</p>
				</div>
				<div class="footer_two_one">
					<img class="footer_two_one_logo" src="../../src/assets/logo.png" alt="">
					<p>工作时间：{{basedetail.time}}</p>
					<p>电话：{{basedetail.tel}}</p>
				</div>
				<div class="footer_two_one">
					<img class="footer_two_one_code" src="../../src/assets/156.png" alt="">
					<p>用户端APP</p>
				</div>
			</div>
			<div class="fotter_bottom">
				<span>{{basedetail.web_site_copyright}} {{basedetail.web_site_address}}</span>
			</div>
		</div>
		<div class="footer_right">
			<div class="footer_right_list" @click="gocart">
				<img src="../assets/164.png" alt="">
				<span>购物车</span>
				<div class="footer_right_list_num" v-if="carttotal>0">
					<span>{{carttotal}}</span>
				</div>
			</div>
			<div class="footer_right_list" @click="goselfcenter()">
				<img src="../assets/165.png" alt="">
				<span>个人中心</span>
			</div>
			<div class="footer_right_list" @click="getChatUrl">
				<img src="../assets/166.png" alt="">
				<span>在线客服</span>
			</div>
			<div class="footer_right_list_jg">

			</div>
			<div class="footer_right_list" @click="toTop">
				<img src="../assets/167.png" alt="">
				<span>回顶部</span>
			</div>
		</div>
		
		<!-- 文本弹窗 -->
		<div class="mb_tc" v-if="wzshow">
			<div class="mb_tc_content">
				<div class="mb_tc_header">
					<span>{{wzdetail.name}}</span>
					<img src="../assets/35.png" @click="wzshow=false" alt="" />
				</div>
				<div class="mb_tc_text">
					<span v-html="wzdetail.content"></span>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				basedetail: "", //网站基本信息
				yszcxyshow: false, //隐私政策协议弹窗
				opentype: 1, //区别注册和隐私协议
				oneurl: "", //获取的当前页面路径
				userInfo: "", //个人信息
				cartListArr:[],//购物车数据
				carttotal:0,//购物车数量
				chat_url:"",//聊天链接
				wzdetail:"",//图文详情
				wzshow:false,//文章弹窗
			}
		},
		mounted() {
			this.oneurl = window.location.href;
			if (localStorage.getItem("userInfo")) {
				this.userInfo = localStorage.getItem("userInfo");
				this.get_list(); //获取购物车
				this.StoreChatgetChatUrl();//获取客服链接
			} else {
				this.userInfo = "";
			}
			this.get_base(); //获取底部基础信息
		},
		methods: {
			//获取网站基本信息
			get_base() {
				this.$http({
					url: '64cdb895b79ba',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.basedetail = res.data.data;
					}
				});
			},
			
			//获取客服链接
			StoreChatgetChatUrl(){
				this.$http({
					url: '64897f6fd5486',
					method: 'post',
					data: JSON.stringify({
						store_id:"1",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.chat_url = res.data.data.chat_url;
					}
				});
			},
			
			
			//获取购物车
			get_list() {
				this.$http({
					url: '62bebd70c4bad',
					method: 'post',
				}).then((res) => {
					if (res.data.code == 1) {
						this.carttotal = res.data.data.length;
					}
				});
			},
			
			//跳转下级页面(一级点击事件)
			gonext(type) {
				if (this.userInfo == '') {
					this.$message({
						message: "请先登录",
						type: "error"
					})
				} else {
					localStorage.setItem("leftindex",16);
					this.$router.push({
						path: "/helpfaceback"
					})
				}
			},

			//跳转购物车
			gocart() {
				if (this.userInfo) {
					this.$router.push({
						path: "goodscart"
					})
				} else {
					this.$message({
						message: "请先登录",
						type: 'error'
					});
				}
			},

			//跳转个人中心
			goselfcenter(type) {
				if (this.userInfo) {
					this.$router.push({
						path: "selfcenter"
					})
				} else {
					this.$message({
						message: "请先登录",
						type: 'error'
					});
				}
			},

			//返回页面顶部
			toTop() {
				document.documentElement.scrollTop = 0;
			},
			
			//获取父组件传过来的商品数量
			getcartnumber(num) {
				this.carttotal = num;
			},
			
			//跳转客服
			getChatUrl(){
				if(localStorage.getItem("userInfo")){
					window.open(this.chat_url)
				}else{
					this.$message({
						message: "请先登录",
						type: 'error'
					});
				}
			},
			
			//各种协议
			openxieyi(index) {
				this.$http({
					url: '641568f1b6f87',
					method: 'post',
					data: JSON.stringify({
						column_id:index,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.wzdetail = res.data.data;
						this.wzshow = true;
					} else {
						this.$message({
							message: res.data.msg,
							type: "error"
						})
					}
				});
				this.xieyishow = true;
			},
		}
	}
</script>

<style scoped>
	.fotter {
		width: 100%;
		overflow: hidden;
		background: #1E1F20;
	}

	.fotter_content {
		width: 1200px;
		height: auto;
		margin: 0 auto;
	}

	.footer_one {
		width: 100%;
		height: auto;
	}

	.footer_one_list {
		width: 25%;
		height: auto;
		float: left;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		color: #FFFFFF;
		margin-top: 48px;
	}

	.footer_one_list img {
		width: 44px;
		height: 44px;
		position: relative;
		top: 15px;
	}

	.footer_two {
		width: 100%;
		overflow: hidden;
	}

	.footer_two_one {
		width: auto;
		height: 100%;
		float: left;
		margin-top: 56px;
		margin-right: 120px;
		text-align: center;
	}

	.footer_two_one h3 {
		font-size: 14px;
		font-weight: bold;
		color: #EEEEEE;
	}

	.footer_two_one p {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		margin-top: 16px;
		cursor: pointer;
	}

	.footer_two_one_logo {
		width: 148px;
		height: 48px;
	}

	.footer_two_one_code {
		width: 80px;
		height: 80px;
	}

	.footer_two_one:last-child {
		margin-right: 0;
	}

	.fotter_bottom {
		width: 100%;
		height: 64px;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 64px;
		border-top: 1px solid #EEEEEE;
		margin-top: 56px;
	}

	.footer_right {
		width: 84px;
		overflow: hidden;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		border: 1px solid #EEEEEE;
		position: fixed;
		right: 0;
		bottom: 100px;
	}

	.footer_right_list {
		width: 100%;
		height: 92px;
		border-bottom: 1px solid #EEEEEE;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		text-align: center;
		cursor: pointer;
		position: relative;
	}

	.footer_right_list_num {
		width: 14px;
		height: 14px;
		line-height: 14px;
		display: block;
		background: #FF5050;
		border-radius: 50%;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		position: absolute;
		right: 20px;
		top: -10px;
	}

	.footer_right_list_jg {
		width: 100%;
		height: 20px;
		background-color: #F7F7F7 !important;
	}

	.footer_right_list img {
		width: 24px;
		height: 24px;
		display: block;
		margin: 20px auto;
	}
	.mb_tc{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;
	}
	.mb_tc_content{
		width: 70%;
		height:80%;
		background-color: #FFFFFF;
		border-radius: 8px;
		margin: 5% auto;
	}
	.mb_tc_header{
		width: 100%;
		height: 66px;
		border-radius: 8px 8px 0 0;
		background-color: #f8f8f8;
		font-size: 24px;
		text-align: center;
		line-height: 66px;
		position: relative;
	}
	.mb_tc_header img{
		width: 32px;
		height: 32px;
		position: absolute;
		right: 32px;
		top: 17px;
		cursor: pointer;
	}
	.mb_tc_text{
		width: 95%;
		height: 85%;
		margin: 2% auto;
		overflow-y: scroll;
	}
	.mb_tc_text::-webkit-scrollbar{
	    display: none;
	}
</style>