import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	//登录
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/login/login.vue')
	},
	{
		path: '/wxlogin',
		name: 'wxlogin',
		component: () => import( /* webpackChunkName: "about" */ '../views/login/wxlogin.vue')
	},
	//企业认证（刚注册完）
	{
		path: '/companyrz',
		name: 'companyrz',
		component: () => import( /* webpackChunkName: "about" */ '../views/login/companyrz.vue')
	},
	//企业认证（已认证）
	{
		path: '/usercompanyrz',
		name: 'usercompanyrz',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usercompanyrz.vue')
	},
	//企业认证(个人中心模块跳转认证)
	{
		path: '/usercompanyrzone',
		name: 'usercompanyrzone',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usercompanyrzone.vue')
	},
	//企业认证(个人中心认证状态)
	{
		path: '/usercompanyrzzhuangtai',
		name: 'usercompanyrzzhuangtai',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usercompanyrzzhuangtai.vue')
	},
	//搜索商品
	{
		path: '/search',
		name: 'search',
		component: () => import( /* webpackChunkName: "about" */ '../views/search.vue')
	},
	//商品列表
	{
		path: '/shoplist',
		name: 'shoplist',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/shoplist.vue')
	},
	//新品中心
	{
		path: '/newgoods',
		name: 'newgoods',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/newgoods.vue')
	},
	//领劵中心
	{
		path: '/coupons',
		name: 'coupons',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/coupons.vue')
	},
	//商品详情
	{
		path: '/goodsdetail',
		name: 'goodsdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/goodsdetail.vue')
	},
	//购物车
	{
		path: '/goodscart',
		name: 'goodscart',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/goodscart.vue')
	},
	//购物车结算
	{
		path: '/shopsettlement',
		name: 'shopsettlement',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/shopsettlement.vue')
	},
	//支付商城订单
	{
		path: '/payshoporder',
		name: 'payshoporder',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/payshoporder.vue')
	},
	//支付商城订单成功
	{
		path: '/payordersuccess',
		name: 'payordersuccess',
		component: () => import( /* webpackChunkName: "about" */ '../views/shop/payordersuccess.vue')
	},
	//个人中心
	{
		path: '/selfcenter',
		name: 'selfcenter',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfcenter.vue')
	},
	//订单列表
	{
		path: '/orderlist',
		name: 'orderlist',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/orderlist.vue')
	},
	//订单详情
	{
		path: '/orderdetail',
		name: 'orderdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/orderdetail.vue')
	},
	//订单评价
	{
		path: '/orderpingjia',
		name: 'orderpingjia',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/orderpingjia.vue')
	},
	//用户管理
	{
		path: '/userlist',
		name: 'userlist',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/userlist.vue')
	},
	//账期管理
	{
		path: '/zhangqiguanli',
		name: 'zhangqiguanli',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/zhangqiguanli.vue')
	},
	//商家入住简介
	{
		path: '/shangjiajointext',
		name: 'shangjiajointext',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/shangjiajointext.vue')
	},
	//商家入住填写信息
	{
		path: '/shangjiajoinedit',
		name: 'shangjiajoinedit',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/shangjiajoinedit.vue')
	},
	//商家入住审核
	{
		path: '/shangjiajoinshenhe',
		name: 'shangjiajoinshenhe',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/shangjiajoinshenhe.vue')
	},
	//我的优惠券
	{
		path: '/usercoupons',
		name: 'usercoupons',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usercoupons.vue')
	},
	//我的收藏
	{
		path: '/usercollect',
		name: 'usercollect',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usercollect.vue')
	},
	//我的足迹
	{
		path: '/userfooter',
		name: 'userfooter',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/userfooter.vue')
	},
	//我的消息
	{
		path: '/usermessage',
		name: 'usermessage',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usermessage.vue')
	},
	//消息详情
	{
		path: '/usermessagedetail',
		name: 'usermessagedetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/usermessagedetail.vue')
	},
	//分享app
	{
		path: '/shareapp',
		name: 'shareapp',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/shareapp.vue')
	},
	//地址列表
	{
		path: '/addresslist',
		name: 'addresslist',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/addresslist.vue')
	},
	//添加地址
	{
		path: '/addaddress',
		name: 'addaddress',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/addaddress.vue')
	},
	//提货人列表
	{
		path: '/tihuolist',
		name: 'tihuolist',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/tihuolist.vue')
	},
	//提货人添加
	{
		path: '/tihuoadd',
		name: 'tihuoadd',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/tihuoadd.vue')
	},
	//发票列表
	{
		path: '/fapiaolist',
		name: 'fapiaolist',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fapiaolist.vue')
	},
	//添加发票
	{
		path: '/fapiaoadd',
		name: 'fapiaoadd',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fapiaoadd.vue')
	},
	//金额统计
	{
		path: '/jinetongji',
		name: 'jinetongji',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/jinetongji.vue')
	},
	//商品统计
	{
		path: '/producttongji',
		name: 'producttongji',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/producttongji.vue')
	},
	//修改密码
	{
		path: '/editpassword',
		name: 'editpassword',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/editpassword.vue')
	},
	//修改手机号
	{
		path: '/editphone',
		name: 'editphone',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/editphone.vue')
	},
	//帮助与反馈
	{
		path: '/helpfaceback',
		name: 'helpfaceback',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/helpfaceback.vue')
	},
	//帮助详情
	{
		path: '/helpfacebackdetail',
		name: 'helpfacebackdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/helpfacebackdetail.vue')
	},
	//意见反馈
	{
		path: '/faceback',
		name: 'faceback',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/faceback.vue')
	},
	//意见反馈详情
	{
		path: '/facebackdetail',
		name: 'facebackdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/facebackdetail.vue')
	},
	//关于我们
	{
		path: '/aboutus',
		name: 'aboutus',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/aboutus.vue')
	},
	//分享列表
	{
		path: '/shareuser',
		name: 'shareuser',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/shareuser.vue')
	},
	//分销列表
	{
		path: '/fenxiao',
		name: 'fenxiao',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fenxiao.vue')
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
//跳转后返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

export default router
